<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item>品牌列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <router-link to="/dashboard/brandadd">
          <el-button type="primary" icon="plus">添加品牌</el-button>
        </router-link>
        <div style="margin-left: 10px"></div>
      </div>
    </div>
    <div class="content-main">
      <div class="filter-box">
        <el-form :inline="true" :model="filterForm" class="demo-form-inline">
          <el-form-item label="用户ID">
            <el-input v-model="filterForm.id" placeholder="用户ID"></el-input>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="filterForm.company" placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item label="品牌">
            <el-input v-model="filterForm.brand" placeholder="品牌"></el-input>
          </el-form-item>
          <el-form-item label="店铺名称">
            <el-input v-model="filterForm.store_name" placeholder="店铺名称"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmitFilter">查询</el-button>
            <el-button @click="resetFilter">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-table-box">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column prop="id" label="ID" width="60" fixed="left"> </el-table-column>
          <el-table-column label="头像" width="80" fixed="left">
            <template scope="scope">
              <img
                :src="scope.row.avatar"
                v-if="scope.row.avatar"
                alt=""
                style="width: 50px; height: 50px"
              />
            </template>
          </el-table-column>

          <el-table-column prop="company" label="公司名称" width="160"> </el-table-column>
          <el-table-column prop="brand" label="品牌" width="160"> </el-table-column>
          <el-table-column prop="store_name" label="店铺名称" width="160"> </el-table-column>
          <el-table-column prop="brief" label="简介" width="160"> </el-table-column>

          <el-table-column label="是否推荐" width="100%">
            <template scope="scope">
              <el-switch
                v-model="scope.row.is_recommond"
                :active-value="1"
                :inactive-value="0"
                active-text=""
                inactive-text=""
                @change="changeRecommondStatus($event, scope.row.id)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column prop="sort" label="排序" width="200" sortable>
            <template scope="scope">
              <el-input-number
                class="sort-width"
                size="mini"
                :min="1"
                :max="100"
                v-model="scope.row.sort"
                placeholder="排序"
                @blur="submitSort(scope.$index, scope.row)"
                @change="submitSort(scope.$index, scope.row)"
              ></el-input-number>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="300">
            <template scope="scope">
              <el-button size="small" @click="handleRowEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="danger"
                @click="handleRowDelete(scope.$index, scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-box" v-if="fake == 0">
        <el-pagination
          background
          @current-change="handlePageChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      total: 0,
      filterForm: {
        id: '',
        company: '',
        store_name: '',
        brand: ''
      },
      tableData: [],
      fake: 0,
      loginInfo: null,
      username: '',
      adminCateOptions: []
    };
  },
  methods: {
    resetFilter() {
      this.filterForm = {
        id: '',
        company: '',
        store_name: '',
        brand: ''
      };
      this.getList();
    },

    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleRowEdit(index, row) {
      this.$router.push({ name: 'brandadd', query: { id: row.id } });
    },
    changeRecommondStatus($event, para) {
      this.axios
        .get('brand/showRecommond', {
          params: {
            status: $event,
            id: para
          }
        })
        .then((response) => {});
    },
    submitSort(index, row) {
      this.axios.post('brand/updateSort', { id: row.id, sort: row.sort }).then((response) => {});
    },
    handleRowDelete(index, row) {
      this.$confirm('确定要删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('brand/destory', { id: row.id }).then((response) => {
          if (response.data.errno === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });

            this.getList();
          }
        });
      });
    },
    onSubmitFilter() {
      this.page = 1;
      this.getList();
    },
    getList() {
      this.axios
        .get('brand', {
          params: {
            page: this.page,
            company: this.filterForm.company,
            id: this.filterForm.id,
            brand: this.filterForm.brand,
            store_name: this.filterForm.store_name
          }
        })
        .then((response) => {
          this.tableData = response.data.data.data;
          this.page = response.data.data.currentPage;
          this.total = response.data.data.count;
        });
      if (!this.loginInfo) {
        this.loginInfo = JSON.parse(window.localStorage.getItem('userInfo') || null);
        this.username = this.loginInfo.username;
      }
    }
  },
  components: {},
  mounted() {
    this.page = 1;
    this.getList();
  }
};
</script>

<style scoped></style>
